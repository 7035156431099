import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Checkbox } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import dayjs from 'dayjs';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { checkNameBranch } from 'src/functions/expand-header';
import { checkStatusShipping, ShippingType } from 'src/functions/order-statuts';
import { getDelivery } from 'src/services/app.services';
import { columnChildTracking } from 'src/utils/app-data';
import { convertCurrency, currencyFormat } from 'src/utils/utils';
import './index.scss';

function MuiTableRow(props: any) {
  const {
    row,
    length,
    selectedRow,
    deleteProduct,
    handleClick,
    index,
    isItemSelected,
    checkboxSelection,
    open,
    handleUpdateTracking,
    branch,
  } = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const [widthCheckbox, setWidthCheckbox] = useState<any>();

  const { data: deliveryData } = useQuery(['delivery'], () => getDelivery({}));

  const nameDelivery = deliveryData?.data?.find((item: any) => item?.id == row?.shipping?.deliveryId);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setWidthCheckbox(`${document?.getElementsByClassName('checkbox')[0]?.getBoundingClientRect()?.width.toFixed()}`);
  }, []);

  window.onresize = function () {
    setWidthCheckbox(`${document?.getElementsByClassName('checkbox')[0]?.getBoundingClientRect()?.width.toFixed()}`);
  };

  return (
    <>
      <TableRow style={{ flexGrow: 1, width: '100%' }}>
        <TableCell
          align={'center'}
          padding="checkbox"
          onClick={(event: any) => {
            handleClick(event, row?.id);
          }}
          style={{ width: '2.4rem' }}
          className={'checkbox'}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Checkbox
              color="primary"
              checked={isItemSelected && checkboxSelection}
              inputProps={{
                'aria-labelledby': 'check-box-' + index,
              }}
            />
          </div>
        </TableCell>
        <TableCell align={'center'} padding="checkbox">
          <div style={{ display: 'flex' }}>
            <VisibilityIcon
              color="primary"
              onClick={() => {
                setOpen(!isOpen);
              }}
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10rem' }}>
              <span>{row?.shipping?.deliveryTrucking}</span>
            </div>
          </div>
        </TableCell>
        <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => {}}>
          {moment(row?.createdAt).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => {}}>
          {nameDelivery?.name}
        </TableCell>
        {/* <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => {}}>
          {convertCurrency(row?.deliveryFree)}
        </TableCell> */}
        <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => {}}>
          {convertCurrency(row?.deliveryCOD)}
        </TableCell>
        <TableCell align="center" style={{ wordBreak: 'break-word' }} onClick={() => {}}>
          {checkStatusShipping(row?.shipping?.status).status}
        </TableCell>
        <TableCell align="center" style={{ wordBreak: 'break-word' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div onClick={handlePrint}>
              <PrintIcon color="primary" fontSize="small" />
            </div>
            <div onClick={() => handleUpdateTracking(row)}>
              <BorderColorIcon color="primary" fontSize="small" />
            </div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8} align="left">
          <Collapse in={isOpen} timeout="auto">
            <div style={{ display: 'flex' }}>
              {row?.shipping?.type !== 'REFUND' ? (
                <div style={{ width: '50%' }} className="view-tracking-table" ref={componentRef}>
                  <div className="view-info-tracking" style={{ padding: '5px' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Người nhận: </span>
                    </div>
                    <div style={{}} className="info-txt">
                      <span className="txt-tracking">{row?.shipping?.customerAddress?.name}</span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px', backgroundColor: '#edf1f2' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Điện thoại: </span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">{row?.shipping?.customerAddress?.phone}</span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Địa chỉ: </span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">{row?.shipping?.customerAddress?.address}</span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px', backgroundColor: '#edf1f2' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Ghi chú:</span>
                    </div>
                    <div className="info-txt">
                      <div className="txt-tracking" style={{ whiteSpace: 'pre-line' }}>{row?.shipping?.note}</div>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Nhận hàng: </span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">
                        {row?.deliveredDate ? dayjs(row.deliveredDate).format('HH:mm DD/MM/YYYY') : ''}
                      </span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px', backgroundColor: '#edf1f2' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Chi nhánh: </span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">{branch}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ width: '50%' }} className="view-tracking-table" ref={componentRef}>
                  <div className="view-info-tracking" style={{ padding: '5px' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Lý do: </span>
                    </div>
                    <div style={{}} className="info-txt">
                      <span className="txt-tracking">{row?.shipping?.reason}</span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px', backgroundColor: '#edf1f2' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Số tiền hoàn: </span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">
                        {currencyFormat(Number(row?.shipping?.deliveryFreeRefund), 'đ')}
                      </span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Chi nhánh: </span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">{checkNameBranch(row?.shipping?.branch)?.branch}</span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px', backgroundColor: '#edf1f2' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Đơn vị:</span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">{nameDelivery?.name}</span>
                    </div>
                  </div>
                  <div className="view-info-tracking" style={{ padding: '5px' }}>
                    <div className="info-title" style={{ marginLeft: `${widthCheckbox - 7}px` }}>
                      <span className="txt-tracking">Ghi chú: </span>
                    </div>
                    <div className="info-txt">
                      <span className="txt-tracking">{row?.shipping?.note}</span>
                    </div>
                  </div>
                </div>
              )}
              <div style={{ width: '70%', padding: '10px' }}>
                <Table sx={{}} size="small" aria-label="a dense table" padding="none">
                  <TableHead>
                    <TableRow>
                      {columnChildTracking?.map((item: any, index: number) => {
                        return (
                          <TableCell align="center" style={{ width: item?.width }} key={item?.field}>
                            {item?.headerName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.shippingOrderDetail?.map((item: any, index: number) => {
                      return (
                        <TableRow>
                          <TableCell align="center" style={{ width: item?.flex }}>
                            {item?.orderDetail.product?.name}
                          </TableCell>
                          <TableCell align="center" style={{ width: item?.flex }}>
                            {item?.quantity}
                          </TableCell>
                          <TableCell align="center" style={{ width: item?.flex }}>
                            <div
                              style={{
                                flexDirection: 'column',
                                display: 'flex',
                                alignItems: 'flex-start',
                              }}
                            >
                              {item?.orderDetail?.product?.productVariations.map((item: any, index: number) => {
                                if (!isEmpty(item.value)) {
                                  return (
                                    <span key={index}>
                                      {item?.name}: {item?.value}
                                    </span>
                                  );
                                }
                              })}
                            </div>
                          </TableCell>
                          <TableCell align="center" style={{ width: item?.flex }}>
                            {currencyFormat(Number(item?.quantity) * Number(item?.price), 'đ')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface expandProps {
  columns: any;
  rows: any;
  deleteProduct?: any;
  isExpand?: boolean;
  checkboxSelection?: boolean;
  onRowClick?: (event: React.MouseEvent<unknown>, name: string) => void;
  loadOnServerSide?: boolean;
  onSelectAllClick?: () => void;
  handleSelectAllClick?: any;
  handleClick: any;
  selected?: any;
  handleUpdateTracking: (type: 'refund' | 'delivery') => (data: any) => void;
}

export default function TrackingTable(props: expandProps) {
  const {
    columns,
    rows,
    deleteProduct,
    isExpand,
    onRowClick = (event: React.MouseEvent<unknown>, name: string) => {},
    handleClick = (event: React.MouseEvent<unknown>, name: string) => {},
    checkboxSelection,
    loadOnServerSide = false,
    selected = [],
    onSelectAllClick,
    handleSelectAllClick,
    handleUpdateTracking,
  } = props;

  const isSelected = (name: string) => {
    return selected?.indexOf(name) !== -1;
  };

  return (
    <>
      <TableContainer component={Paper} className="expand-table-order">
        <Table aria-label="collapsible table" style={{ flexGrow: 1 }}>
          <TableHead>
            <TableRow>
              {checkboxSelection && (
                <TableCell align={'center'}>
                  <Checkbox
                    color="primary"
                    indeterminate={selected && selected?.length > 0 && selected?.length < rows?.length}
                    checked={selected && rows?.length > 0 && selected?.length === rows?.length}
                    onChange={loadOnServerSide ? onSelectAllClick : handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
              )}
              {columns?.map((col: any, i: number) => {
                return (
                  <TableCell align={'center'} key={col.field}>
                    {col?.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: any, index: number) => {
              const isItemSelected = isSelected(row.id);
              let in_process_refund = row?.type === ShippingType.REFUND;
              return (
                <MuiTableRow
                  handleClick={handleClick}
                  key={row.skuProductId + index}
                  row={row}
                  length={index}
                  deleteProduct={deleteProduct}
                  isItemSelected={isItemSelected}
                  checkboxSelection={checkboxSelection}
                  handleUpdateTracking={handleUpdateTracking(in_process_refund ? 'refund' : 'delivery')}
                  branch={checkNameBranch(rows?.[index].shippingOrderDetail?.[0]?.branch)?.branch}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
